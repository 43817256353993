@import './lightmodeColors.scss';

.active-pill-link,
.active-pill-link:hover {
  button {
    background-color: $active;
    color: $text-active !important;
    border-color: $transparent;
  }

  * {
    color: darken($header-pill-text, 20%);
  }
}

.active-pill-link {
  span {
    color: white !important;
  }
}

.active-pill-link-promo {
  span {
    color: #159A42 !important;
  }
}

.no-drag {
  -webkit-user-drag: none;
  user-drag: none;
}
