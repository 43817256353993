.dateFilterComponent {
  .custom-date-input {
    background: #ffff;
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
  }

  .custom-date-input:hover {
    background: #f6faed;
  }

  #placeholder-text {
    color: #6e756b !important;
  }
  .react-datepicker {
    border: 0 !important;
    border-radius: 12px !important;
    background: #f6faed;
    backdrop-filter: blur(73px);
    -webkit-backdrop-filter: blur(73px);

    .react-datepicker__triangle::after {
      border-bottom-color: #f6faed !important;
    }
  }

  .react-datepicker__header {
    background: #f6faed;
    backdrop-filter: blur(73px);
    -webkit-backdrop-filter: blur(73px);

    .react-datepicker__current-month,
    .react-datepicker__day-name {
      color: black;
    }
  }
  .react-datepicker__day {
    color: #545c50 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.4;
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent !important;
    background: none !important;
    color: #6e756b !important;
  }

  .react-datepicker__day:hover {
    color: #545c50 !important;
    background: var(
      --1,
      linear-gradient(180deg, rgba(64, 69, 61, 0.3) 0%, rgba(63, 70, 63, 0.3) 100%)
    );
    backdrop-filter: blur(11.5px);
    -webkit-backdrop-filter: blur(11.5px);
  }

  .react-datepicker__day--selected {
    background: #f0c45d !important;

    color: #131614 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 18px !important; /* 112.5% */
    text-transform: uppercase;
  }

  .react-datepicker__day--keyboard-selected {
    color: #131614 !important;
    background: #f6faed;
    backdrop-filter: blur(73px);
    -webkit-backdrop-filter: blur(73px);
  }
}
