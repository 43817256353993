$font-family-sans-serif: "Poppins", -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono";

$btn-border-radius: 0.75rem;

//banner
$carousel-control-color: white;
$carousel-control-width: 10%;
$carousel-control-opacity: .6;
$carousel-control-hover-opacity: .95;
$carousel-control-transition: opacity .15s ease;
$carousel-control-icon-width: 4rem;

$carousel-indicator-width: 50px;
$carousel-indicator-height: 10px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 4px;
$carousel-indicator-opacity: 0.4;
$carousel-indicator-active-bg: orange; // this has to be overriden in the  css file with !important becasue can't be overriden
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;

@import '_CONSTANTS.scss';

$container-max-widths: (
  sm: $sm-screen,
  md: $md-screen,
  lg: $lg-screen,
  xl: $xl-screen,
  xxl: $xxl-screen //only this one is changed to match the figma design
);

// spinner
$spinner-width:           5rem;
$spinner-height:          $spinner-width;
$spinner-vertical-align:  -.125em;
$spinner-border-width:    .5em;
$spinner-animation-speed: .75s;

$spinner-width-sm:        1rem;
$spinner-height-sm:       $spinner-width-sm;
$spinner-border-width-sm: .2em;

$grid-row-columns: 12;


//accordion
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1.25rem;

$accordion-border-width:                  0;
// $accordion-border-color:                  var(--#{$prefix}border-color);
$accordion-border-radius:                 20px;
$accordion-inner-border-radius:           20px;

$accordion-body-padding-y:                $accordion-padding-y;
$accordion-body-padding-x:                $accordion-padding-x;

$accordion-button-padding-y:              $accordion-padding-y;
$accordion-button-padding-x:              $accordion-padding-x;

// $accordion-transition:                    $btn-transition, border-radius .15s ease;
// $accordion-button-focus-border-color:     $input-focus-border-color;
// $accordion-button-focus-box-shadow:       $btn-focus-box-shadow;

$accordion-icon-width:                    1.25rem;

$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(-180deg);

