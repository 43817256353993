.table-wrapper {
    th > span {
        color: #545C50;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }  
    .card:nth-child(even) {
        background: #edf0e6 !important;
    }    
}
