$primary: #DCE7CF;
$danger : #dc3545;
$danger-light: #EDC949; // if we only danger in light mode
$light : #545C50;
$success: #159A42;
$transparent: rgba(255, 255, 255, 0);
//custom theme colors
$black : #2A2E29;
$white : #F6FAED;
$white-light: $white;
$color-text: rgba(19, 22, 20, 1);
$login-btn: #fff;
$login-btn-text: #545C50;
$menu-text: rgba(19, 22, 20, 1);
$drawer-menu-icon: rgba(77, 83, 74, 1);
$active: #159A42;
$active-soft: rgba(189,226,195,1);
$text-active: #fff;
$active-text: $text-active;
$secondary-item: rgba(84, 92, 80, 1);
$active-bg: rgba(255, 255, 255, 0.40);
$modal-bg: #BEC8A9;
$drawer-menu-bg: rgba(246, 250, 237, 1);
$bottom-menu-bg: $drawer-menu-bg;
$contrast: #dcdddb;

$red: #FF4040;
 
//background
$background: #DCE5D0;
$firstBg : #DCE7CF;
$secondBg : $color-text;

//footer
$footerBg: #EDF3E5;
$footer-item-text: #131614;

//pills
$header-pill-bg: #F6FAED;
$header-pill-text: #545C50;
$header-pill-active-bg: $success;
$header-pill-active-text: $secondBg;
$winning-numbers-bg: #F8FAF6;

$success-border: rgba(255, 255, 255, 0.12);

// x on modal
$btn-close-color: $black;

//casino tab
$casino-tab-header: $light;
$search-button-border: $light;

//cashier cards
$card-top: #ECF3DF;
$card-bottom: rgba(246, 250, 237, 0.35);

//accordion
$accordion-color: $active; // Sass variable because of $accordion-button-icon
$accordion-bg: $card-top;

$accordion-button-color: $active;

$accordion-button-active-bg: tint-color($active, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

// $accordion-button-bg: $active;

$accordion-icon-color: $accordion-button-color;
$accordion-icon-active-color: $accordion-button-active-color;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$color-text}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

$button-input: $success;
$button-input-text: #F5F9EE;

//promo code
$promo-code-bg: rgba(246, 250, 237, 0.50);

$card-background: $footerBg;



// lottery tournament