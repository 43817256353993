@import './overridesBootstrap.scss';
@import './lightmodeColors.scss';

$theme-colors: (
  'primary': $primary,
  'danger': $danger,
  'danger-light': $danger-light,
  'light': $light,
  'success': $success,
  'black': $black,
  'white': $white,
  'login-btn': $login-btn,
  'login-btn-text': $login-btn-text,
  'menu-text': $menu-text,
  'active': $active,
  'active-soft': $active-soft,
  'text-active': $text-active,
  'active-text': $active-text,
  'secondary-item': $secondary-item,
  'active-bg': $active-bg,
  'white-light': $white-light,
  'success-border': $success-border,
  'color-text': $color-text,
  'header-pill-bg': $header-pill-bg,
  'header-pill-text': $header-pill-text,
  'header-pill-bg-active': $header-pill-active-bg,
  'header-pill-text-active': $header-pill-active-text,
  'modal-bg': $modal-bg,
  'drawer-menu-bg': $drawer-menu-bg,
  'bottom-menu-bg': $bottom-menu-bg,
  'winning-numbers-bg': $winning-numbers-bg,
  'footer-item-text': $footer-item-text,
  'casino-tab-header': $casino-tab-header,
  'search-button-border': $search-button-border,
  'card-top': $card-top,
  'card-bottom': $card-bottom,
  'button-input': $button-input,
  'button-input-text': $button-input-text,
  'promo-code-bg': $promo-code-bg,
  'contrast': $contrast,
  'card-background': $card-background,
  'red': $red,
);

/* Note: All the bootstrap overrides go above this line */
@import '../../node_modules/bootstrap/scss/bootstrap';
/* Note:  all the new functionalities which are not overrides go below this line */

:root {
  --modal-close-btn-bg: white;
  --modal-close-icon-color: #4f4f4f;
  --modal-close-btn-hover: #f1f1f1;
  --modal-bg: #dce5d0;
}

.carousel-btn.active {
  background-color: $active !important;
}

.custom-carousel .carousel-indicators button {
  background-color: $active-bg;
}

.custom-carousel .carousel-indicators .active {
  background-color: $active !important;
  opacity: 1;
}

.carousel-btn {
  background-color: $secondary !important;
}

.drawer-menu-bg {
  background: $drawer-menu-bg;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.bottom-menu-bg {
  background: $bottom-menu-bg;
}

.nav-link {
  transition-property: none;
}

//default
.nav-item {
  .nav-link {
    color: $menu-text;
    stroke: $menu-text;
    fill: none;
    text-transform: uppercase;
  }

  .secondary-item {
    font-size: 15px;
    color: $secondary-item;
    text-transform: none;
  }

  &:has(.active-item) {
    // Styles applied when .active-item is a descendant of .nav-item
    background: $active-bg;
  }
}

//hover
.nav-item {
  .nav-link:hover {
    color: $active;
    stroke: $active;
    fill: none;
    background: $active-bg;

    .secondary-item {
      color: $active !important;
    }
  }
}

.active-item {
  color: $active !important;

  svg {
    stroke: $active;
    // fill: transparent;
  }
}

.footer {
  background-color: $footerBg;
}

.footer-item {
  color: $footer-item-text;

  a {
    color: $footer-item-text;
  }
}

.footer-item:hover {
  color: $active;

  a {
    color: $active;
  }
}

.active {
  color: $active;
}

.line {
  color: $footer-item-text;
}

.footer:before {
  background-color: $background;
}

.carousel-btn {
  background-color: $secondary !important;
}

.drawer-menu-bg {
  background: $drawer-menu-bg;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.nav-link {
  transition-property: none;
}

//default
.nav-item {
  .nav-link {
    color: $menu-text;
    stroke: $menu-text;
    fill: none;
    text-transform: uppercase;
  }

  .secondary-item {
    font-size: 15px;
    color: $secondary-item;
    text-transform: none;
  }

  &:has(.active-item) {
    // Styles applied when .active-item is a descendant of .nav-item

    background: $active-bg;
  }
}

//hover
.nav-item {
  .nav-link:hover {
    color: $active;
    stroke: $active;
    fill: none;
    background: $active-bg;

    .secondary-item {
      color: $active !important;
    }
  }
}

.active-item {
  color: $active !important;

  * {
    color: $active !important;
  }

  svg {
    stroke: $active;
    // fill: transparent;
  }
}

.footer {
  background-color: $footerBg;
}

.footer-item a {
  color: $footer-item-text;
}

.footer-item a:hover {
  color: $active;
}

.active a {
  color: $active;
}

.line {
  color: $footer-item-text;
}

.footer:before {
  background-color: $background;
}

.carousel-btn {
  background-color: $secondary !important;
}

.drawer-menu-bg {
  background: $drawer-menu-bg;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.bottom-menu-bg {
  background: $bottom-menu-bg;
}

.nav-link {
  transition-property: none;
}

//default
.nav-item {
  .nav-link {
    color: $menu-text;
    stroke: $menu-text;
    fill: none;
    text-transform: uppercase;
  }

  .secondary-item {
    font-size: 15px;
    color: $secondary-item;
    text-transform: none;
  }

  &:has(.active-item) {
    // Styles applied when .active-item is a descendant of .nav-item
    background: $active-bg;
  }
}

//hover
.nav-item {
  .nav-link:hover {
    color: $active;
    stroke: $active;
    fill: none;
    background: $active-bg;

    .secondary-item {
      color: $active !important;
    }
  }
}

.active-item {
  color: $active !important;

  svg {
    stroke: $active;
    // fill: transparent;
  }
}

.footer {
  background-color: $footerBg;
}

.footer-item a {
  color: $footer-item-text;
}

.footer-item a:hover {
  color: $active;
}

.active a {
  color: $active;
}

.line {
  color: $footer-item-text;
}

.footer:before {
  background-color: $background;
}

.carousel-btn {
  background-color: $secondary !important;
}

body {
  background: $background !important;
}

$scrollbar-width: 0.5rem;

body,
.drawer-menu-scrollbar {
  scrollbar-width: $scrollbar-width * 1.7;
  scrollbar-color: $active;
}

body::-webkit-scrollbar,
.drawer-menu-scrollbar::-webkit-scrollbar {
  width: $scrollbar-width * 1.7;
  height: 0.5rem;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb,
.drawer-menu-scrollbar::-webkit-scrollbar-thumb {
  background-color: $active;
  border-radius: 0.2rem;
  transition: background-color 0.2s;
}

.drawer-menu-scrollbar::-webkit-scrollbar {
  width: $scrollbar-width;
  background-color: transparent;
}

.nav-login-btn {
  border: none;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth and visibility */
}

@media (max-width: 575.98px) {
  .headerContainer {
    .nav-login-btn,
    .sign-up-button {
      font-size: 12px !important;
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}

.nav-login-btn:hover {
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(180deg, rgba(44, 47, 42, 0.3) 0%, rgba(37, 41, 37, 0.3) 100%);
}

.clear-btn {
  border: none;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(180deg, rgba(44, 47, 42, 0.3) 0%, rgba(37, 41, 37, 0.3) 100%);
}

.clear-btn:hover {
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(180deg, rgba(44, 47, 42, 0.3) 0%, rgba(37, 41, 37, 0.3) 100%);
}

.header-btn {
  text-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.img-acc-balance {
  background: url('/icons/account-balance-button-light.svg') no-repeat center center !important;
  width: 7.875rem;
  height: 2.5rem;
}

.img-inbox {
  background: url('/icons/inbox-icon-light.svg') no-repeat center center !important;
  width: 2.4375rem;
  height: 2.4375rem;
  flex-shrink: 0;
}

.img-account {
  background: url('/icons/account-icon-light.svg') no-repeat center center !important;
  width: 2.4375rem;
  height: 2.4375rem;
  flex-shrink: 0;
}

.svg-level-icon-text {
  stroke: $color-text;
}

@media (max-width: 600px) {
  .img-acc-balance {
    background: url('/icons/account-balance-button-light-mobile.svg') no-repeat center center !important;
    width: 6.125rem !important;
    height: 1.5rem !important;
    flex-shrink: 0 !important;
  }

  .img-inbox {
    width: 1.5rem !important;
    height: 1.5rem !important;
    flex-shrink: 0;
    border-radius: 50%;
  }

  .img-account {
    width: 1.5rem !important;
    height: 1.5rem !important;
    flex-shrink: 0;
  }
}

.headerContainer {
  background-color: $background;
  z-index: 200 !important;
}

.borderCard {
  background: rgba(246, 250, 237, 0.5);
  color: $active !important;
  stroke: $active;
  border: 2px solid transparent;
}

// .borderCard:hover {
//   border: 2px solid $active;
//   background-color: $active-bg;
// }

.showMore {
  background: #f6faed;

  .title {
    color: $color-text;
  }

  .mainContent {
    color: #545c50;
  }
}

/* PROMOTIONS*/
.card-theme-background {
  background: #f6faed;
}

//Casino Tab
.search-button {
  border: 3px solid $casino-tab-header;
  color: $black;
}

.search-button:focus {
  outline: 0px solid $light;
}

.header-title {
  color: $casino-tab-header;
}

.load-more-btn {
  border: 3px solid $casino-tab-header;
  color: $casino-tab-header;
}

.load-more-btn:hover {
  border: 3px solid $active;
  color: $active;
}

.next-btn {
  box-shadow:
    0px 0 0px 0px rgba(0, 0, 0, 0),
    -20px 0 12px 9px rgba(16, 20, 18, 0.5);
}

.slick-prev:before,
.slick-next:before {
  color: $active !important;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: $active !important;
}

/* CHECKBOX */
.checkboxComponent {
  .custom-checkbox-validation {
    color: #ff0000 !important;
  }
}
.form-check-input {
  background-color: #ffffff !important;
  border-color: #869282 !important;
}
.form-check-input:checked {
  background-color: #159a42 !important;
  border-color: #159a42 !important;
}

/* END CHECKBOX */

/* DROPDOWN */
.dropdownComponent {
  .custom-dropdown .custom-dropdown-label {
    color: #2c2c2c !important;
  }

  .custom-dropdown .custom-dropdown-button {
    color: #2c2c2c !important;
    background-color: #ffffff !important;
    border-color: #869282 !important;
  }

  .custom-dropdown .custom-dropdown-menu {
    background-color: #ffffff !important;
    border: 0 !important;
  }

  .custom-dropdown .custom-dropdown-item {
    color: #545c50 !important;
  }

  .custom-dropdown .custom-dropdown-item:hover {
    color: #131614 !important;
    background-color: #f5f5f5 !important;
    border-radius: 12px !important;
  }
}

/* END DROPDOWN */

/* INPUT */
.inputComponent {
  .custom-input {
    background-color: #ffffff !important;
  }

  .custom-input::placeholder {
    color: #869282 !important;
  }

  .custom-input:focus {
    border: 2px solid #e6ece3 !important;
  }

  .custom-input:disabled {
    color: #545c50 !important;
  }
}

/* END INPUT */

/* MODAL */
.modalComponent {
  .custom-modal-header {
    background-color: $background;
    color: #2c2c2c;
  }

  .custom-modal-title {
    color: #159a42;
  }

  .modal-content {
    background-color: $background;
  }

  .custom-modal-footer {
    background-color: $firstBg;
    color: #2c2c2c;
  }

  .btn-close {
    color: #2c2c2c;
  }

  /* For Webkit browsers (Chrome, Safari) */
  .custom-modal-body::-webkit-scrollbar {
    width: 10px;
  }

  .custom-modal-body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .custom-modal-body::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  .custom-modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* For Firefox */
  .custom-modal-body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
}

/* END MODAL */

//footer modals
.modal {
  .subtitle {
    color: $active;
  }
}

.line-heading {
  display: flex;
  flex: 1;
  width: 100%;
  margin: 20px auto;
  line-height: 1em;
}

.line-heading:before,
.line-heading:after {
  content: '';
  flex-grow: 1;
  margin: 0px 4px;
  background: linear-gradient(to right, $active, $active);
  background-size: 100% 2px;
  background-position: 0% 50%;
  background-repeat: repeat-x;
}

.p-highlight {
  font-weight: bold !important;
  background-color: $active !important;
  color: $text-active !important;
}

/* Casino Desktop Launcher*/
.main-nav-content .header-search:hover {
  color: $active;
}
.main-nav-content .header-search {
  color: $active;
}
.m-button {
  border: 1.6px solid #364039;
}
.m-button:active {
  background: #edb327;
  color: black;
}
.m-button:hover {
  background: #b8b8b8;
  outline: #364039;
  color: white;
}
.game-header {
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(180deg, rgba(44, 47, 42, 0.3) 0%, rgba(37, 41, 37, 0.3) 100%);
}
#app {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.nav-sidebar-icon:hover {
  background: #b8b8b8;
  border-radius: 8px;
}
.nav-sidebar-wrapped .divider-bottom::after {
  background: #364039;
}
.nav-sidebar-wrapped .divider-top::before {
  background: #364039;
}

input[type='checkbox']:checked {
  accent-color: #196a2d !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #196a2d !important;
  background: #ffffff !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #196a2d !important;
}

.react-form-wizard .wizard-icon-circle.md[aria-selected='false'] > .wizard-icon-container {
  background: #ffffff !important;
}
.wizard-title {
  color: black !important;
}

.bg-third {
  background-color: #ffffff !important;
  color: #6e756b !important;
}

.progress {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.progress-bar {
  background: linear-gradient(
    135deg,
    rgba(69, 112, 231, 0.8) 0%,
    rgba(176, 106, 179, 0.8) 50%,
    rgba(242, 166, 90, 0.8) 100%
  );
}

.progress-bar:hover {
  background: linear-gradient(
    135deg,
    rgba(69, 112, 231, 1) 0%,
    rgba(176, 106, 179, 1) 50%,
    rgba(242, 166, 90, 1) 100%
  );
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.headerPill {
  &:hover,
  &:hover .icon,
  &:hover .text {
    color: black !important;
    fill: black !important;
    background: $white;
    border-width: 1px !important;
    border: 1px solid $active !important;
  }
  border-width: 1px !important;

  &:active {
    border-width: 1px !important;
    border: 1px solid $active !important;
  }
}
@import '../components/Containers/PageContainer/styles/PageContainer.light.scss';
@import './_pillLink.light.scss';
@import '../components/Table/styles/_table.light.scss';
@import '../pages/Balances/styles/balances.light.scss';
@import '../components/FormItems/DateFilter/styles/dateFilter.light.scss';
@import '../pages/Cashier/components/Deposit/styles/deposit.light.scss';
@import '../pages/Cashier/components/Deposit/CreditCard/styles/creditCard.light.scss';
